import { Dialog, Popover, Transition } from "@headlessui/react";
import { Bars3Icon, ShoppingBagIcon, UserIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import type { CartResponse, HeaderData } from "~/types";

import { Link, useFetcher, useRouteLoaderData } from "@remix-run/react";
import Neum, { NeumButton } from "./neum.component";

import { isBlackFridayPromoEnabled } from "~/utils/dates";
import blackFridayDesktop from "../images/black-friday/BlackFriday-Desktop.jpg";
import blackFridayMobile from "../images/black-friday/BlackFriday-Mobile.jpg";

import type { Customer } from "~/shopify/types/gql/graphql";
import { Logo } from "./logo.component";
import { MenuItemComponent } from "./menuItem.component";
import Modal from "./modal.component";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function Header({
  header,
  customer,
}: {
  header: HeaderData;
  customer?: Customer;
}) {
  const showBanner = isBlackFridayPromoEnabled();

  let initials = undefined;
  if (customer !== undefined) {
    const firstNameLetter = customer.firstName?.[0] ?? "";
    const lastNameLetter = customer.lastName?.[0] ?? "";
    initials = firstNameLetter + lastNameLetter;
  }

  const [open, setOpen] = useState(false);
  const { menu } = header;

  const fetcher = useFetcher<CartResponse>();

  const [totalCart, setTotalCart] = useState(0);

  useEffect(() => {
    window.addEventListener("cartUpdated", (event: CustomEvent<number>) => {
      if (typeof event.detail === "number") {
        setTotalCart(event.detail);
      }
    });
  }, []);

  useEffect(() => {
    if (fetcher.state === "idle" && !fetcher.data) {
      fetcher.load("/cart");
    }

    if (fetcher.data && fetcher.state === "idle") {
      const { cart } = fetcher.data as CartResponse;
      if (cart) {
        setTotalCart(cart.totalQuantity);
      } else {
        setTotalCart(0); // O cualquier otro valor predeterminado si cart no está definido
      }
    }
  }, [fetcher.data, fetcher.state, fetcher.load]);

  return (
    <div>
      {/* Subscription modal */}
      {header.showSignUpModal && <Modal />}

      {/* Menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 flex " onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-md" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex h-full w-full max-w-xs translate-x-0 flex-col overflow-y-auto bg-admirerWhite pb-12 text-black shadow-xl dark:bg-admirerBlack dark:text-white lg:max-w-sm">
              <div className="flex px-4 pb-2 pt-5 lg:hidden">
                <Neum rounded={true}>
                  <XMarkIcon
                    className="m-1 h-8 w-8 flex-shrink-0 cursor-pointer rounded-full group-hover:text-admirerBlue"
                    aria-hidden="true"
                    onClick={() => setOpen(false)}
                  />

                  {/* <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">0</span> */}
                </Neum>
                <span className="sr-only">Close menu</span>

                <div className="ml-auto mt-2">
                  {initials === undefined ? (
                    <div>
                      <Link
                        to="/user/sign-in"
                        className="text-white-900 mr-1 text-base font-light outline-none focus:ring-0 focus:ring-offset-0"
                        onClick={() => setOpen(false)}
                      >
                        Sign in /
                      </Link>

                      <Link
                        to="/user/sign-up"
                        className="text-white-900 text-base font-light focus:ring-0 focus:ring-offset-0"
                        onClick={() => setOpen(false)}
                      >
                        Sign up
                      </Link>
                      <span className="sr-only">User</span>
                      <UserIcon
                        className="float-right mt-1 h-5 w-5 flex-shrink-0 group-hover:text-admirerBlue"
                        aria-hidden="true"
                        onClick={() => setOpen(false)}
                      />
                    </div>
                  ) : (
                    <div>
                      <Link
                        to="/user"
                        onClick={() => setOpen(false)}
                        className="text-white-900 mr-1 text-sm font-light outline-none focus:ring-0 focus:ring-offset-0"
                      >
                        {initials}
                        <span className="sr-only">User</span>
                        <UserIcon
                          className="float-right mt-1 h-5 w-5 flex-shrink-0 focus:ring-0 focus:ring-offset-0 group-hover:text-admirerBlue"
                          aria-hidden="true"
                          onClick={() => setOpen(false)}
                        />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="hidden px-4 pb-2 pt-5 lg:flex">
                <Logo subdomain={header.subdomain} />
                <div className="ml-auto">
                  <XMarkIcon
                    className="m-1 h-8 w-8 flex-shrink-0 cursor-pointer rounded-full group-hover:text-admirerBlue"
                    aria-hidden="true"
                    onClick={() => setOpen(false)}
                  />
                </div>
              </div>
              {/* Links */}

              <div className="space-y-6  px-6 py-4">
                <ul className="-mx-2 space-y-1">
                  {menu.items.map((menudata) => (
                    <MenuItemComponent key={menudata.id} menuItem={menudata} isFirstLevel={true} setOpen={setOpen} />
                  ))}
                </ul>
              </div>

              <div className="space-y-6  px-4 py-5 lg:hidden">
                {initials === undefined ? (
                  <div>
                    <NeumButton rounded={true}>
                      <Link
                        id="mobile-currency"
                        className="text-white-700 group-hover:text-white-800 block w-full border-transparent bg-transparent py-1 text-center text-base font-bold focus:border-transparent focus:ring-0"
                        to={"/user/sign-in"}
                        onClick={() => setOpen(false)}
                      >
                        Sign In
                      </Link>
                    </NeumButton>
                    <p className="py-4 text-center">Or</p>
                    <NeumButton rounded={true}>
                      <Link
                        id="mobile-currency"
                        className="text-white-700 group-hover:text-white-800 block w-full border-transparent bg-transparent py-1 text-center text-base font-bold focus:border-transparent focus:ring-0"
                        to={"/user/sign-up"}
                        onClick={() => setOpen(false)}
                      >
                        Sign Up
                      </Link>
                    </NeumButton>
                  </div>
                ) : (
                  <div>
                    <NeumButton rounded={true}>
                      <Link
                        id="mobile-currency"
                        className="text-white-700 group-hover:text-white-800 block w-full border-transparent bg-transparent py-1 text-center text-base font-bold focus:border-transparent focus:ring-0"
                        to={"/user"}
                        onClick={() => setOpen(false)}
                      >
                        View my Profile
                      </Link>
                    </NeumButton>
                  </div>
                )}
              </div>

              {header?.subdomain && header.subdomain !== "127" && (
                <p className="mt-auto text-center align-bottom text-sm font-light text-gray-400">
                  Or visit{" "}
                  <Link to="https://admirer.store" className="text-gray-400">
                    Admirer.store
                  </Link>
                </p>
              )}
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <header className="relative">
        <nav aria-label="Top">
          {/* Secondary navigation */}
          <div className="">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mt-1 flex h-14 items-center justify-between lg:mt-0">
                <div className="flex flex-1 items-center">
                  <Neum rounded={true}>
                    <Bars3Icon
                      className="m-2 h-8 w-8 flex-shrink-0 p-1 group-hover:text-admirerBlue md:h-6 md:w-6 lg:m-0 lg:h-9 lg:w-9 lg:p-2"
                      aria-hidden="true"
                      onClick={() => setOpen(true)}
                    />
                  </Neum>
                  <span className="sr-only">Open menu</span>
                  <Popover.Group className="inset-x-0 bottom-0 px-4">
                    <div className="hidden space-x-8 lg:flex lg:items-center">
                      <p onClick={() => setOpen(true)} className="!-ml-2 cursor-pointer">
                        Menu
                      </p>
                    </div>
                  </Popover.Group>
                </div>

                <a href="/" className="">
                  <span className="sr-only">Admirer</span>
                  <Logo subdomain={header.subdomain} />
                </a>

                <div className="flex flex-1 items-center justify-end">
                  <div className="flex items-center lg:ml-8 ">
                    {/* Help */}

                    <div className="ml-4 hidden md:block lg:ml-2">
                      {initials === undefined ? (
                        <div className="flex">
                          <Popover.Group as="nav" className="relative">
                            <Popover className="">
                              {({ open }) => (
                                <>
                                  <Popover.Button
                                    className={classNames(
                                      open ? "text-admirerblue" : "text-white",
                                      "bg-blac group inline-flex items-center rounded-md pt-1.5 text-base font-medium hover:text-gray-900 focus:outline-none ",
                                    )}
                                  >
                                    <UserIcon
                                      className="mr-1 flex h-5 w-5 items-center text-black group-hover:text-admirerBlue dark:text-white "
                                      aria-hidden="true"
                                      onClick={() => setOpen(false)}
                                    />
                                  </Popover.Button>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                  >
                                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-28 max-w-md  transform lg:left-1/2 lg:ml-0 lg:max-w-2xl lg:-translate-x-1/2">
                                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div className="relative grid gap-2 bg-admirerWhite px-2 py-0 dark:bg-admirerBlack-700 sm:gap-6 sm:p-4 lg:grid-cols-1">
                                          <Link
                                            to="/user/sign-in"
                                            className="text-white-900 float-left mr-1 text-sm font-light"
                                          >
                                            Sign in
                                          </Link>
                                          <Link
                                            to="/user/sign-up"
                                            className="text-white-900 float-left text-sm font-light"
                                          >
                                            Sign up
                                          </Link>
                                        </div>
                                      </div>
                                    </Popover.Panel>
                                  </Transition>
                                </>
                              )}
                            </Popover>
                          </Popover.Group>

                          <div className="flex items-center">
                            <Link to="/user/sign-in" className="text-white-900 mr-1 text-base font-light">
                              Sign in /
                            </Link>

                            <Link to="/user/sign-up" className="text-white-900 text-base font-light">
                              Sign up
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <Link to="/user" className="">
                            <UserIcon
                              className="mr-1 flex h-5 w-5 items-center text-black group-hover:text-admirerBlue dark:text-white"
                              aria-hidden="true"
                              onClick={() => setOpen(false)}
                            />
                          </Link>
                          <Link to="/user" className="mr-1 text-base font-light text-black dark:text-white">
                            {initials}
                          </Link>
                        </div>
                      )}
                    </div>

                    {/* Cart */}
                    <div className="ml-1 flow-root min-w-[60px] lg:ml-2">
                      <a href="/cart" className="group flex items-center p-2 pr-0">
                        {totalCart > 0 && (
                          <span className="z-10 -mr-6 mt-6 w-6 rounded-3xl bg-admirerBlue p-0.5 text-center text-sm font-medium text-white">
                            {totalCart}
                          </span>
                        )}

                        <Neum rounded={true} className="m-0 ml-3 mr-0 flex h-full">
                          <ShoppingBagIcon
                            className="m-2 h-8 w-8 flex-shrink-0 p-1 group-hover:text-admirerBlue md:h-6 md:w-6 lg:m-0 lg:h-9 lg:w-9 lg:p-2"
                            aria-hidden="true"
                          />
                        </Neum>

                        <span className="sr-only">items in cart, view bag</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {showBanner && (
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <img className="hidden h-full w-full lg:block" src={blackFridayDesktop} alt="black friday" />
            <img className="h-full w-full pt-2 lg:hidden" src={blackFridayMobile} alt="black friday" />
          </div>
        )}
      </header>
    </div>
  );
}
