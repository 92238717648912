import type { FunctionComponent } from "react";

// TODO: @andres please rename the logos to LogoWhiteLetter and LogoBlackLetter
import logoHref from "../images/Logo.svg";
import homeLogo from "../images/logos/HomeAdmirer.svg";
import kitchenLogo from "../images/logos/KitchenAdmirer.svg";
// import logoHrefBlack from "../images/LogoBlack.svg";
import petsLogo from "../images/logos/PetsAdmirer.svg";
import toysLogo from "../images/logos/ToysAdmirer.svg";

// import beautyLogo from "../images/logos/BeautyAdmirer.svg";
// import sportsLogo from "../images/logos/SportsAdmirer.svg";
// import travelLogo from "../images/logos/TravelAdmirer.svg";
// import fashionLogo from "../images/logos/FashionAdmirer.svg";
// import electronicsLogo from "../images/logos/ElectronicsAdmirer.svg";

const getLogo = (subdomain: string | undefined): string => {
  if (!subdomain) return logoHref;
  const subdomainMappings: Record<string, string> = {
    pets: petsLogo,
    toys: toysLogo,
    home: homeLogo,
    kitchen: kitchenLogo,
    local: petsLogo, // TODO delete this line
    // Add more mappings as needed
  };

  return subdomainMappings[subdomain] ?? logoHref;
};

// TODO: if svg can change the color of the letters we don't need LogoDarkMode anymore
const getLogoDarkMode = (subdomain: string | undefined): string => {
  if (!subdomain) return logoHref;
  const subdomainMappings: Record<string, string> = {
    pets: petsLogo,
    toys: toysLogo,
    home: homeLogo,
    kitchen: kitchenLogo,
    local: petsLogo, // use this logo for local development
    // Add more mappings as needed
  };

  return subdomainMappings[subdomain] ?? logoHref;
};

export const Logo: FunctionComponent<{
  subdomain: string | undefined;
}> = ({ subdomain }) => {
  return (
    <div>
      <img
        className="hidden h-10 w-auto fill-current text-green-500 dark:block"
        src={getLogoDarkMode(subdomain)}
        alt="admirer"
      />
      <img
        className="block h-10 w-auto fill-current text-green-500 dark:hidden"
        src={getLogo(subdomain)}
        alt="admirer"
      />
    </div>
  );
};
