import { json, redirect } from "@remix-run/cloudflare";
import type { LoaderFunction, MetaFunction } from "@remix-run/cloudflare";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { commitSession, destroySession, getSession } from "~/sessions";
import { Footer } from "./components/footer.component";
import { Header } from "./components/header.component";
import AppError from "./components/root/appError";
import RouteError from "./components/root/routeError";
import { TEN_DAYS } from "./constants";
import useScript from "./hooks/useScript";
import CustomerGQL from "./shopify/API/customer.server";
import { getMenu } from "./shopify/API/header-menu.server";
import type { CustomerAccessToken, Menu } from "./shopify/types/gql/graphql";
import "./styles/critical.css";
import type { RootLoader } from "./types";
import { getSubdomain, getSubdomainCategory } from "./utils/subdomain";

export const meta: MetaFunction = () => {
  return [{ title: "Admirer store - The fastest way to buy" }];
};

export const loader: LoaderFunction = async ({
  request,
}: {
  request: Request;
}) => {
  const currencies = ["USD", "CAD", "AUD", "EUR", "GBP"];
  const languages = ["English", "Español", "Français", "中國人"];
  let menu = await getMenu();
  // filter menu items using the subdomain
  const subdomain = getSubdomain(request.url);
  if (subdomain) {
    const subdomainCategory = getSubdomainCategory(subdomain);
    if (subdomainCategory) {
      const newMenu = menu.items.find((item) => {
        const linkArray = (item?.url as string)?.split("/collections/");
        const categoryLink = linkArray?.[1];
        if (categoryLink === subdomainCategory) {
          return item;
        }
      });
      if (newMenu) {
        menu = newMenu as unknown as Menu;
      }
    }
  }
  const session = await getSession(request.headers.get("Cookie"));

  const navigationFooter = {
    main: [
      { name: "About us", href: "/about-us" },
      { name: "FAQs", href: "/faqs" },
      { name: "Terms of Service", href: "/help/terms-of-service" },
      { name: "Privacy Policy", href: "/help/privacy-policy" },
      { name: "Shipping Policy", href: "/help/shipping-policy" },
      { name: "Return Policy", href: "/help/return-policy" },
      {
        name: "Contact Us: contact@admirer.store",
        href: "mailto:contact@admirer.store",
      },
    ],
    social: [
      {
        name: "Facebook",
        href: "https://www.facebook.com/admirerstore",
        icon: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
      },
      {
        name: "Instagram",
        href: "https://www.instagram.com/admirer_store/",
        icon: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
      },
      {
        name: "Twitter",
        href: "https://twitter.com/admirerstore",
        icon: "M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84",
      },
      // {
      //   name: "Pinterest",
      //   href: "https://pin.it/723dYev",
      //   icon: "M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z",
      // },
    ],
  };

  const modalShown = !!session.get("signupModalShown");
  const showSignUpModal = !session.has("customerAccessToken") && !modalShown;

  const header = { currencies, languages, menu, subdomain, showSignUpModal };
  const footer = navigationFooter;

  if (session.has("customerAccessToken")) {
    const customerAccessToken = session.get("customerAccessToken") as CustomerAccessToken;
    const { customer, error } = await CustomerGQL.getCustomer(customerAccessToken.accessToken);
    if (error) {
      return redirect("/", {
        headers: {
          "Set-Cookie": await destroySession(session),
        },
      });
    }
    return json({ header, footer, customer });
  }
  if (!session.has("signupModalShown")) {
    const options = { maxAge: TEN_DAYS };
    session.set("signupModalShown", true);
    return json(
      { header, footer },
      {
        headers: {
          "Set-Cookie": await commitSession(session, options),
        },
      },
    );
  }

  return json({ header, footer });
};

const handleThemeColor = () => {
  const themeColor = "";

  //TODO DOESNT WORK WITH SSR
  // if (typeof window !== "undefined") {
  //   if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
  //     themeColor = "dark";
  //   } else {
  //     themeColor = "";
  //   }
  // } else {
  //   themeColor = "";
  // }
  // console.log(themeColor);

  // https://www.mattstobbs.com/remix-dark-mode/

  return themeColor;
};

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  // const theme = handleThemeColor();

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    return <RouteError error={error} />;
  }

  return <AppError error={error as Error} />;
}

export function Layout({ children }: { children: React.ReactNode }) {
  const theme = handleThemeColor();
  // Google Tag Manager <script> content
  const gaTrackingId = "GTM-KBCDDS76";
  const gtagContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gaTrackingId}');
              `;
  useScript("gtag-init", gtagContent);
  const data = useRouteLoaderData("root") as RootLoader;

  // Meta Pixel Code <script> content
  const fbPixelContent = `
    if(typeof fbq === 'undefined') {
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '262125596456965');
      fbq('track', 'PageView');
    }
    else {
      fbq('track', 'PageView');
    }
  `;
  useScript("fb-pixel", fbPixelContent);

  return (
    <html lang="en" className={theme}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="google-site-verification" content="ZnEV0zvKsWD6OBxxz9r5kd4iFl_3sS52OJL70uGPtU0" />
        <link
          href="https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;700;800&display=swap"
          rel="stylesheet"
        />
        <Meta />
        <Links />
      </head>
      <body className="bg-admirerWhite font-mulish dark:bg-admirerBlack h-screen text-black dark:text-white">
        {data ? <Header header={data.header} customer={data.customer} /> : null}
        <div id="content" className="mx-auto max-w-7xl px-4 pt-3 sm:px-6 md:pt-0 lg:px-8">
          {children}
        </div>
        {data ? <Footer subdomain={data.header.subdomain} footer={data.footer} /> : null}
        <ScrollRestoration />
        <Scripts />
        {/* Google Tag Manager */}
        <noscript>
          <iframe
            title="gtm"
            src={`https://www.googletagmanager.com/ns.html?id=${gaTrackingId}`}
            height="0"
            width="0"
            className="hidden"
          />
        </noscript>
        {/* End Google Tag Manager */}
        {/* Meta Pixel Code */}
        <noscript>
          <img
            height="1"
            width="1"
            alt="fb-pixel"
            className="hidden"
            src="https://www.facebook.com/tr?id=262125596456965&ev=PageView&noscript=1"
          />
        </noscript>
        {/* End Meta Pixel Code */}
      </body>
    </html>
  );
}

export function App() {
  return <Outlet />;
}

export default withSentry(App);
