import { Disclosure, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "@remix-run/react";
import type { FunctionComponent } from "react";
import type { MenuItem } from "~/shopify/types/gql/graphql";
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export const MenuItemComponent: FunctionComponent<{
  menuItem: MenuItem;
  isFirstLevel: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ menuItem, isFirstLevel, setOpen }) => {
  const linkArray = (menuItem?.url as string)?.split("/collections/");
  const categoryLink = linkArray?.[1];

  return (
    <li className="outline-none ring-0" key={menuItem.id}>
      {menuItem.items?.length > 0 ? (
        <div className="outline-none ring-0">
          <Disclosure
            as="div"
            className={isFirstLevel ? "border-b border-gray-400 text-lg font-light outline-none ring-0" : ""}
          >
            {({ open }) => (
              <>
                <Disclosure.Button className="text-white-900 flex w-full items-center gap-x-3  rounded-md p-2 text-left text-sm font-semibold leading-6 outline-none ring-0 lg:p-3">
                  <Link
                    to={`/category/${categoryLink}`}
                    className={`text-white-900 -m-2 block px-0 py-2  focus:outline-none ${
                      isFirstLevel ? "text-lg font-light lg:text-base lg:font-bold" : "text-base font-medium lg:text-sm"
                    } `}
                    onClick={() => setOpen(false)}
                  >
                    {menuItem.title}
                  </Link>
                  <ChevronRightIcon
                    className={classNames(
                      open ? "rotate-90 text-gray-900" : "text-gray-800",
                      "ml-auto h-5 w-5 shrink-0",
                    )}
                    aria-hidden="true"
                  />
                </Disclosure.Button>

                <Transition
                  enter="transition duration-1300 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-400 opacity-100"
                  leave="transition duration-1300 ease-out"
                  leaveFrom="transform scale-400 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel as="ul" className="-mt-1 px-2 lg:pb-2">
                    {menuItem.items.map((subMenuItem) => (
                      <MenuItemComponent
                        key={subMenuItem.id}
                        menuItem={subMenuItem}
                        isFirstLevel={false}
                        setOpen={setOpen}
                      />
                    ))}
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      ) : (
        <Link
          to={`/category/${categoryLink}`}
          className={`text-white-900 block p-2 px-0 py-2 lg:p-2.5 lg:pl-1 ${
            isFirstLevel
              ? "border-b border-gray-400 text-lg font-light lg:text-base lg:font-bold"
              : "text-base font-medium lg:text-sm"
          } focus:outline-none`}
          onClick={() => setOpen(false)}
        >
          {menuItem.title}
        </Link>
      )}
    </li>
  );
};
