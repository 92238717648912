import { Links, Meta, Scripts, ScrollRestoration } from "@remix-run/react";
import logoHref from "~/images/Logo.svg";
import deadFaceHref from "~/images/icon-faces/dead.svg";
import { NeumButton } from "../neum.component";

export default function AppError({ error }: { error: Error }) {
  return (
    <div>
      <div id="content" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div>
          <main>
            <div className="mt-20 md:mt-32">
              <img
                className="m-auto my-4 w-1/4 content-center md:my-8 md:w-32"
                src={deadFaceHref}
                alt="dead face admirer"
              />
              <p className="p-3 text-center text-sm font-normal text-admirerBlack-100 md:p-8 md:text-2xl">
                Oops, something went wrong, don&apos;t worry, we&apos;re on it. Go to home page and keep browsing.
              </p>
              <div className="m-auto w-5/6 content-center md:w-3/12">
                <a className="text-center" href="/">
                  <NeumButton rounded={true}>Go Back Home</NeumButton>
                </a>
              </div>
              {typeof process !== "undefined" && process?.env?.NODE_ENV === "development" && (
                <div className="py-10">
                  <h2 className="text-center text-2xl font-extrabold md:text-4xl">Application Error</h2>
                  <p className="p-3 text-center text-sm font-normal text-admirerBlack-100 md:p-8 md:text-2xl">
                    {error.message}
                  </p>
                  <pre className="text-normal bg-red-700 p-3 text-left font-normal text-white md:p-8">
                    {error.stack}
                  </pre>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
