import { type ErrorResponse, Links, Meta, Scripts, ScrollRestoration } from "@remix-run/react";
import logoHref from "~/images/Logo.svg";
import worryFaceHref from "~/images/icon-faces/worry.svg";
import { NeumButton } from "../neum.component";

export default function RouteError({ error }: { error: ErrorResponse }) {
  return (
    <div>
      <header className="relative">
        <nav aria-label="Top">
          {/* Secondary navigation */}
          <div className="">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                {/* Logo (lg+) */}
                <div className="hidden lg:flex lg:flex-1 lg:items-center">
                  <a href="/">
                    <span className="sr-only">Admirer</span>
                    <img className="h-6 w-auto sm:h-8" src={logoHref} alt="admirer" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div id="content" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div>
          <main>
            <div className="mt-20 md:mt-32">
              <img
                className="m-auto my-4 w-1/4 content-center md:my-8 md:w-32"
                src={worryFaceHref}
                alt="worry face admirer"
              />
              <h1 className="text-center text-3xl font-extrabold md:text-6xl">
                <span className="text-admirerBlue">{error.status}</span> {error.statusText}
              </h1>
              <p className="p-3 text-center text-sm font-normal text-admirerBlack-100 md:p-8 md:text-2xl">
                Sorry, we couldn&apos;t find the page you&apos;re looking for.
              </p>
              <div className="m-auto w-5/6 content-center md:w-3/12">
                <a className="text-center" href="/">
                  <NeumButton rounded={true}>Go Back Home</NeumButton>
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
