import { Dialog, Transition } from "@headlessui/react";
import { Link } from "@remix-run/react";
import { Fragment, useState } from "react";
import modalDesktop from "../images/modal-signup/modalDesktop.jpg";
import modalMobile from "../images/modal-signup/modalMobile.jpg";
import { NeumButton } from "./neum.component";

export default function Modal() {
  const [open, setOpen] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700/50 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg  text-left shadow-xl transition-all sm:my-8 sm:flex sm:w-full sm:max-w-2xl">
                <div className="sm:flex sm:w-1/2 sm:justify-center">
                  <img src={modalMobile} alt="signup discount" className="w-auto sm:hidden" />
                  <img src={modalDesktop} alt="signup discount" className="hidden w-full sm:block" />
                </div>
                <div className="bg-admirerWhite px-4 pb-4 pt-5 sm:flex sm:w-1/2 sm:flex-col sm:justify-center">
                  <div className="mb-3 text-center sm:mt-5">
                    <Dialog.Title as="h1" className="text-xl font-semibold leading-6 text-gray-900 sm:text-2xl">
                      Get 15% off your first order!
                    </Dialog.Title>
                    <div className="mt-2 sm:my-3">
                      <p className="text-sm text-gray-500 sm:text-base">
                        Sign up and accept marketing emails to unlock this special offer
                      </p>
                    </div>
                  </div>
                  <NeumButton rounded={true}>
                    <Link
                      id="mobile-currency"
                      className="text-white-700 group-hover:text-white-800 block w-full border-transparent bg-transparent py-1 text-center text-base font-bold focus:border-transparent focus:ring-0"
                      to={"/user/sign-up"}
                      onClick={() => setOpen(false)}
                    >
                      Sign Up
                    </Link>
                  </NeumButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
